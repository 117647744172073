<template>
  <div id="">
    <CCard class="rounded shadow-sm">
      <CCardBody>
        <div class="d-flex justify-content-start">
          <div class="col-3 pl-0">
            <input
              type="search"
              class="form-control"
              placeholder="Try searching the program name"
              v-model="searchValue"
            />
          </div>

          <div>
            <button
              @click="
                getPrograms(true, {
                  loader: { enabled: false },
                  refreshLoader: { enabled: true },
                })
              "
              class="btn btn-outline-secondary text-dark mr-2"
            >
              Refresh
            </button>
            <div
              v-if="refreshLoader"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <table
          id="table-of-programs"
          class="table table-hover table-bordered table-sm mt-3"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>DISC. CODE</th>
              <th>PROGRAM NAME</th>
              <th width="30%">REFERENCE</th>
              <th>UPDATED BY</th>
              <th>UPDATED ON</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-if="!filteredPrograms.length && !tableLoader"
            >
              <td colspan="11">
                No results found
              </td>
            </tr>
            <tr class="text-center" v-if="tableLoader">
              <td colspan="11">
                <div class="spinner-border spinner-border-sm mr-1"></div>
                Loading
              </td>
            </tr>
            <tr
              v-for="program in filteredPrograms"
              :key="program.id"
              @click.stop="pushRoute(program)"
            >
              <th>{{ program.id }}</th>
              <td>{{ program.discipline_code }}</td>
              <td>
                {{ program.program_name }}
                <small
                  v-if="program.former_name"
                  class="form-text text-muted mt-0"
                >
                  Former Name: {{ program.former_name }}</small
                >
              </td>

              <td>
                <div v-for="(ref, index) in program.refs" :key="index">
                  <a href="#" @click.stop="openUrl(ref.file)" class="mr-2">
                    <i class="bi bi-file-pdf"></i> {{ ref.code }}</a
                  >
                </div>
              </td>
              <td>{{ program.updated_by }}</td>
              <td>
                <span v-if="program.updated_at">{{
                  program.updated_at | standardDate
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'Programs',
  data() {
    return {
      updateMode: false,
      searchValue: '',
      tableLoader: false,
      refreshLoader: false,
      form: new Form({
        id: '',
        program_name: '',
        discipline_code: '',
        major: '',
        level_code: '',
        cmo: '',
        filename: '',
        file_path: '',
        refs: '',
      }),
      areas: [],
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    openModal() {
      this.updateMode = false;
      this.form.reset();
      $('#add-program-modal').modal('show');
    },

    openUpdateProgramModal(program) {
      this.updateMode = true;
      $('#add-program-modal').modal('show');
      this.form.fill(program);
    },

    add() {
      let b = document.getElementById('add-program-btn');
      b.innerHTML = 'Adding...';
      b.disabled = true;

      axios
        .post('api/programs', this.form)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          b.innerHTML = 'Add';
          b.disabled = false;
          $('#add-program-modal').modal('hide');
          this.getPrograms(true, {
            loader: { enabled: false },
            refreshLoader: { enabled: false },
          });
        })
        .catch(() => {
          toastr.error('Something went wrong.', 'Error');
          b.innerHTML = 'Add';
          b.disabled = false;
        });
    },

    isValidated() {},
    getPrograms(hasChanges, options) {
      if (this.$store.state.isProgramsMounted && !hasChanges) {
        return;
        // execute code when the component is already mounted
      }

      this.refreshLoader = options.refreshLoader.enabled;
      this.tableLoader = options.loader.enabled;
      axios
        .get('api/programs')
        .then((response) => {
          this.tableLoader = false;
          this.refreshLoader = false;
          this.$store.state.programs = response.data;
          this.$store.state.isProgramsMounted = true;
        })
        .catch((error) => {
          this.tableLoader = false;
          this.refreshLoader = false;
        });
    },

    pushRoute(program) {
      this.$router.push({
        name: 'ProgramDetails',
        params: {
          id: program.id,
          program: program,
        },
      });
    },
  },
  computed: {
    filteredPrograms() {
      let temp = this.$store.state.programs;
      if (this.searchValue != '') {
        temp = temp.filter((program) => {
          return program['program_name']
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getPrograms(false, {
      loader: { enabled: true },
      refreshLoader: { enabled: false },
    });
  },
};
</script>

<style scoped>
#table-of-programs tr {
  cursor: pointer !important;
}
</style>
